export interface IUser
{
  id: number;
  loginId: string;

  firstName: string;
  lastName: string;
  email: string;

  loginCount: number;
  createdDate: Date;
  lastUpdatedDate: Date;

  isDeleted: boolean;
  isAccountLocked: boolean;
  accountLockedReason: string;
  lastLoginDate: Date;
  allowInteractiveLogin: boolean;
  passwordResetRequired: boolean;
  passwordResetTempPasswordTimeoutDate: Date;
  passwordLastResetDate: Date;

  permissions: string[];

  havePermission(permissionCode: string): boolean
}

export class User
{
  public id: number;
  public loginId: string;

  public firstName: string;
  public lastName: string;
  public email: string;

  public loginCount: number;
  public createdDate: Date;
  public lastUpdatedDate: Date;

  public isDeleted: boolean;
  public isAccountLocked: boolean;
  public accountLockedReason: string;
  public lastLoginDate: Date;
  public allowInteractiveLogin: boolean;
  public passwordResetRequired: boolean;
  public passwordResetTempPasswordTimeoutDate: Date;
  public passwordLastResetDate: Date;

  public permissions: string[];

  constructor() { }

  public havePermission(permissionCode: string): boolean
  {
    return this.permissions.any(permission => permission === permissionCode);
  }

}
