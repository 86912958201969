<div class="background-login">
  <app-dialog [enableCloseDialogButton]="false" [show]="showModal" [preventCloseFromBackDrop]="true" height="auto">
    <div class="p-5 h-100 w-100 d-flex flex-column">
      <div class="text-end position-absolute" style="top: 2%; right: 3%;">
        <i class="fa fa-times fs-xl" (click)="onHandleCloseModal()" style="cursor: pointer;"></i>
      </div>
      <div class="mx-auto my-4 fs-xl">
        <img src="../../../assets/logo.png" alt="" height="60px">
      </div>
      <div class="text-center mx-auto">
        <p class="text-title">Registro</p>
        <h2>&Uacute;nete y descubre Aliwell<sup>&#174;</sup></h2>
  
        <div class="d-flex gap-4 align-items-center my-4" *ngIf="!isSignUpComplete">
          <p class="opacity-50 fs-md" [ngClass]="{'active': showSpecialistForm}">Soy especialista</p>
          <label class="switch">
            <input type="checkbox" [checked]="!showSpecialistForm" (change)="showSpecialistForm = !showSpecialistForm">
            <span class="slider"></span>
          </label>
          <p class="opacity-50 fs-md" [ngClass]="{'active': !showSpecialistForm}">Soy residente</p>
        </div>
      </div>
  
      <!-- SPECIALIST FORM -->
      <div *ngIf="showSpecialistForm && !isSignUpComplete" class="flex-grow-1">
        <form #specialistForm="ngForm" class="d-flex flex-column justify-content-around align-items-center h-100">
          <div class="w-100 d-flex flex-column gap-3">
            <div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                <input matInput placeholder="Nombre" name="name" id="name" required [(ngModel)]="userSignUpSpecialist.name">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['name'])">Completa este campo</mat-error>
              </mat-form-field>
              <div class="d-flex gap-2">
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                  <input matInput placeholder="Apellido paterno" name="lastName" id="lastName" required [(ngModel)]="userSignUpSpecialist.lastName">
                  <mat-error *ngIf="specialistForm.form.hasError('required', ['lastName'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                  <input matInput placeholder="Apellido materno" name="secondLastName" id="secondLastName" required [(ngModel)]="userSignUpSpecialist.secondLastName">
                  <mat-error *ngIf="specialistForm.form.hasError('required', ['secondLastName'])">Completa este campo</mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-id-card mr-2">&nbsp;</span>
                <input matInput placeholder="Cédula profesional" name="professionalLicense" id="professionalLicense" required [(ngModel)]="userSignUpSpecialist.professionalLicense">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['professionalLicense'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-checkbox class="w-100" name="hasOrthodonticsSpecialty" id="hasOrthodonticsSpecialty" required [(ngModel)]="userSignUpSpecialist.hasOrthodonticsSpecialty" (change)="getCheckBoxError()">
                ¿Cuenta con especialidad en ortodoncia? *
              </mat-checkbox>
              <mat-error *ngIf="showError" style="font-size: x-small;">Completa este campo</mat-error>

              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-envelope mr-2">&nbsp;</span>
                <input matInput placeholder="Correo electrónico" name="email" id="email" required [(ngModel)]="userSignUpSpecialist.email" email (input)="userSignUpSpecialistEmailChanged()">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['email'])">Completa este campo</mat-error>
                <mat-error *ngIf="specialistForm.form.hasError('email', ['email'])">Ingresa un correo electrónico valido</mat-error>
              </mat-form-field>
            </div>
  
            <div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-briefcase mr-2">&nbsp;</span>
                <input matInput placeholder="Nombre del consultorio" name="clinicName" id="clinicName" required [(ngModel)]="userSignUpSpecialist.clinicName">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['clinicName'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                <input matInput placeholder="Persona de contacto" name="contactName" id="contactName" required [(ngModel)]="userSignUpSpecialist.contactName">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['contactName'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-phone mr-2">&nbsp;</span>
                <input matInput phoneNumberOnly maxlength="10" minlength="10" placeholder="Teléfono" name="phone" id="phone" required [(ngModel)]="userSignUpSpecialist.phone" (input)="userSignUpSpecialistPhoneChanged()">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['phone'])">Completa este campo</mat-error>
                <mat-error *ngIf="specialistForm.form.hasError('minlength', ['phone'])">Ingresa un número válido (10 dígitos)</mat-error>
                <mat-error></mat-error>
              </mat-form-field>
              <div class="d-flex gap-2">
                <mat-form-field class="w-75">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput placeholder="Calle" name="street" id="street" required [(ngModel)]="userSignUpSpecialist.street">
                  <mat-error *ngIf="specialistForm.form.hasError('required', ['street'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput phoneNumberOnly placeholder="Número" name="number" id="number" [(ngModel)]="userSignUpSpecialist.number">
                </mat-form-field>
              </div>
              <div class="d-flex gap-2">
                <mat-form-field class="w-75">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput placeholder="Ciudad" name="city" id="city" required [(ngModel)]="userSignUpSpecialist.city">
                  <mat-error *ngIf="specialistForm.form.hasError('required', ['city'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput phoneNumberOnly maxlength="5" placeholder="Código Postal" name="zipCode" id="zipCode" required [(ngModel)]="userSignUpSpecialist.zipCode">
                  <mat-error *ngIf="specialistForm.form.hasError('required', ['zipCode'])">Completa este campo</mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                <mat-label>Selecciona un estado</mat-label>
                <mat-select matInput name="state" id="state" required state [(ngModel)]="userSignUpSpecialist.state">
                  <mat-option *ngFor="let state of States" value="{{state.name}}">{{state.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="specialistForm.form.hasError('required', ['state'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                <input matInput placeholder="País" name="country" id="country" required [(ngModel)]="userSignUpSpecialist.country">
                <mat-error *ngIf="specialistForm.form.hasError('required', ['country'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-globe mr-2">&nbsp;</span>
                <input matInput placeholder="Sitio web" name="website" id="website" [(ngModel)]="userSignUpSpecialist.website">
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-share-alt mr-2">&nbsp;</span>
                <input matInput placeholder="Social media" name="socialMedia" id="socialMedia" [(ngModel)]="userSignUpSpecialist.socialMedia">
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
  
      <!-- RESIDENT FORM -->
      <div *ngIf="!showSpecialistForm && !isSignUpComplete">
        <form #residentForm="ngForm">
          <div class="w-100 d-flex flex-column gap-2">
            <div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                <input matInput placeholder="Nombre" name="name" id="name" required [(ngModel)]="userSignUpResident.name">
                <mat-error *ngIf="residentForm.form.hasError('required', ['name'])">Completa este campo</mat-error>
              </mat-form-field>
              <div class="d-flex gap-2">
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                  <input matInput placeholder="Apellido paterno" name="lastName" id="lastName" required [(ngModel)]="userSignUpResident.lastName">
                  <mat-error *ngIf="residentForm.form.hasError('required', ['lastName'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                  <input matInput placeholder="Apellido materno" name="secondLastName" id="secondLastName" required [(ngModel)]="userSignUpResident.secondLastName">
                  <mat-error *ngIf="residentForm.form.hasError('required', ['secondLastName'])">Completa este campo</mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="mr-2">
                <mat-label>Fecha graduación</mat-label>
                <input matInput [(ngModel)]="userSignUpResident.graduationDate"
                       calendarValidation
                       readonly
                       [matDatepicker]="pickerGraduationDate"
                       name="graduationDate"
                       [min]="nowDate"
                       (dateChange)="setGraduationDate($event)">
                <mat-datepicker-toggle matSuffix [for]="pickerGraduationDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerGraduationDate startView="year"></mat-datepicker>
                <mat-error *ngIf="residentForm.form.hasError('matDatepickerMin', ['graduationDate'])">La fecha de graduación debe ser mayor o igual a la fecha actual</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-envelope mr-2">&nbsp;</span>
                <input matInput placeholder="Correo electrónico" name="email" id="email" required [(ngModel)]="userSignUpResident.email" email (input)="userSignUpResidentEmailChanged()">
                <mat-error *ngIf="residentForm.form.hasError('required', ['email'])">Completa este campo</mat-error>
                <mat-error *ngIf="residentForm.form.hasError('email', ['email'])">Ingresa un correo electrónico valido</mat-error>
              </mat-form-field>
            </div>
  
            <div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-university mr-2">&nbsp;</span>
                <input matInput placeholder="Nombre de la institución" name="institutionName" id="institutionName" required [(ngModel)]="userSignUpResident.institutionName">
                <mat-error *ngIf="residentForm.form.hasError('required', ['institutionName'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                <input matInput placeholder="Nombre del coordinador" name="coordinatorName" id="coordinatorName" required [(ngModel)]="userSignUpResident.coordinatorName">
                <mat-error *ngIf="residentForm.form.hasError('required', ['coordinatorName'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-phone mr-2">&nbsp;</span>
                <input matInput phoneNumberOnly maxlength="10" minlength="10" placeholder="Teléfono" name="phone" id="phone" required [(ngModel)]="userSignUpResident.phone" (input)="userSignUpResidentPhoneChanged()">
                <mat-error *ngIf="residentForm.form.hasError('required', ['phone'])">Completa este campo</mat-error>
                <mat-error *ngIf="residentForm.form.hasError('minlength', ['phone'])">Ingresa un número válido (10 dígitos)</mat-error>
              </mat-form-field>
              <div class="d-flex gap-2">
                <mat-form-field class="w-75">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput placeholder="Calle" name="street" id="street" required [(ngModel)]="userSignUpResident.street">
                  <mat-error *ngIf="residentForm.form.hasError('required', ['street'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-25">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput phoneNumberOnly placeholder="Número" name="number" id="number" [(ngModel)]="userSignUpResident.number">
                </mat-form-field>
              </div>
              <div class="d-flex gap-2">
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput placeholder="Ciudad" name="city" id="city" required [(ngModel)]="userSignUpResident.city">
                  <mat-error *ngIf="residentForm.form.hasError('required', ['city'])">Completa este campo</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                  <input matInput phoneNumberOnly maxlength="5" placeholder="CP" name="zipCode" id="zipCode" required [(ngModel)]="userSignUpResident.zipCode">
                  <mat-error *ngIf="residentForm.form.hasError('required', ['zipCode'])">Completa este campo</mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                <mat-label>Selecciona un estado</mat-label>
                <mat-select matInput name="state" id="state" required state [(ngModel)]="userSignUpResident.state">
                  <mat-option *ngFor="let state of States" value="{{state.name}}">{{state.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="residentForm.form.hasError('required', ['state'])">Completa este campo</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                <input matInput placeholder="País" name="country" id="country" required [(ngModel)]="userSignUpResident.country">
                <mat-error *ngIf="residentForm.form.hasError('required', ['country'])">Completa este campo</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
  
      <div class="w-100 text-center mt-4" *ngIf="!isSignUpComplete">
        <div class="mx-auto" *ngIf="loading" style="width: fit-content;">
          <app-loading></app-loading>
        </div>
        <button class="button primary-button w-75 mx-auto" *ngIf="!loading" (click)="signUp()">Registrarme</button>
        <p class="mt-2 fs-sm opacity-50">Dando clic en "Registrarme" estoy de acuerdo con los <a href="https://www.aliwell.mx/aviso-de-privacidad"  target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">Avisos de Privacidad</a></p>
      </div>
  
      <div class="w-100 text-center mt-4 d-flex flex-column gap-2" *ngIf="isSignUpComplete">
        <p class="fs-md">¡Tu registro se ha completado exitosamente!</p>
        <img src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/undraw/undraw_helpful_sign.svg" alt="Registro exitoso">
        <p>Te hemos enviado un correo electr&oacute;nico con tu información.</p>
        <p>Favor de consultar nuestro <a href="https://www.aliwell.mx/aviso-de-privacidad" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">aviso de privacidad</a></p> 
        <button class="button primary-button w-75 mx-auto" (click)="reset()">Aceptar</button>
      </div>
  
    </div>
  </app-dialog>

</div>
