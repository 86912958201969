export class Tools
{
  public static css_rule_set(selector: any, property: any, value: any, important: any)
  {
    for (let i = document.styleSheets.length - 1; i >= 0; i--)
    {
      try
      {
        let ss = document.styleSheets[i];
        let r: any = ss.cssRules ? ss.cssRules : ss.rules;

        for (let j = 0; j < r.length; j++)
        {
          if (r[j].selectorText && r[j].selectorText == selector)
          {
            if (typeof important == 'undefined')
            {
              r[j].style.setProperty(property, value);
            }
            else
            {
              r[j].style.setProperty(property, value, 'important');
            }
            break;
          }
        }
      }
      catch (e)
      {
      }
    }
  }

  public static getOffset()
  {
    return (new Date()).getTimezoneOffset();
  }

  /**
   * Merge the contents of two or more objects together into the first object.
   *  Returns the merged object.
   *
   * @param deep      If true, the merge becomes recursive (aka. deep copy).
   * @param target    The object to extend.  It will receive the new properties
   * @param object1   An object containing additional properties to merge in.
   * @param objectN   Additional objects containing properties to merge in.
   */
  public static extend(deep: boolean, target: any, object1?: any, ...objectN: any[]): any
  {
    let i = 0;
    let length = arguments.length;

    // Check if a deep merge
    if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]')
    {
      deep = arguments[0];
      i++;
    }

    // Merge the object into the extended object
    let merge = function (obj: any)
    {
      for (let prop in obj)
      {
        if (Object.prototype.hasOwnProperty.call(obj, prop))
        {
          // If deep merge and property is an object, merge properties
          if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]')
          {
            target[prop] = Tools.extend(true, target[prop], obj[prop]);
          }
          else
          {
            target[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++)
    {
      let obj = arguments[i];
      merge(obj);
    }

    return target;
  }

  /**
   * Redirects for any given route
   */
  public static redirectToRoute(routeName: string, message?: any)
  {
  }

  /**
   * Displays the app Exception page
   */
  public static displayExceptionPage(message?: any)
  {
    Tools.redirectToRoute("exception", message);
  }
  /**
   * Throws any given exception and redirects you to an exception page
   * If an url param DoNotRedirect is set, it will throw the exception
   *
   * @param exceptionMessage   The exception message.
   */
  public static throwException(exceptionMessage: string)
  {
    Tools.displayExceptionPage();
  }
  /**
 * Validates if the string value is part of the enum.
 */
  public static isValidEnumValue(value: string, enumeration: object): boolean
  {
    if (value === undefined || value == null || value === '')
    {
      return false;
    }

    for (let key in enumeration)
    {
      if (value === enumeration[key])
      {
        return true;
      }
    }

    return false;
  }



  // ********************************************************************************
  // #region Filter Helpers
  // ********************************************************************************


  public static filterString(value: string, data: any[]): string[]
  {
    let filterValue = value.toLowerCase();

    return data.filter(option => option.toLowerCase().includes(filterValue));
  }

  public static filterObjectName(value: string, data: any[]): string[]
  {
    let filterValue = value.toLowerCase();

    return data.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }

  public static groupBy(date: any[], key)
  {
    return date.reduce((result, currentValue) =>
    {
      (result[currentValue[key]] = result[currentValue[key]] || []).
        push(currentValue);
      return result;
    }, {});
  };

  public static sortingDataAccessor(item, property)
  {
    if (property.includes('.'))
    {
      return property.split('.')
        .reduce((object, key) => object[key], item);
    }
    return item[property];
  }

  public static compare(a: number | string, b: number | string, isAsc: boolean)
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // #endregion Filters

}
