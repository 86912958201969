import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `<div class="modal hide" tabindex="-1" [ngClass]="{'show': show}">
              <div class="modal-dialog modal-dialog-centered" style="max-width: {{width}}; max-height: {{height}};">
                <div class="modal-content">
                  <span class="modal-header justify-content-end icon-close-error" (click)="show = false" *ngIf="enableCloseDialogButton">
                    <mat-icon>close</mat-icon>
                  </span>
                   <div class="modal-body">
                    <ng-content></ng-content>
                  </div>
                   <div class="modal-footer" *ngIf="enableConfirmButton">
                    <button mat-button class="modal-button button {{ confirmButtonClass }}" (click)="show = false">{{ confirmButtonText }}</button>
                  </div>
                  <div class="modal-footer" *ngIf="hasCallBack">
                    <button mat-button class="modal-button button lh-1 {{ cancelButtonClass }}" (click)="show = false; cancelCallBackFunction()">{{ cancelButtonText }} <mat-icon *ngIf="cancelButtonIcon != ''">{{ cancelButtonIcon }}</mat-icon></button>
                    <button appDebounceClick mat-button class="modal-button button lh-1 {{ confirmButtonClass }}" (debounceClick)="callBackFunction()">{{ confirmButtonText }} <mat-icon *ngIf="confirmButtonIcon != ''">{{ confirmButtonIcon }}</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>`,
  styleUrls: ['./dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DialogComponent implements OnChanges
{
  @Input() public enableCloseDialogButton: boolean = true;
  @Input() public enableConfirmButton: boolean = false;
  @Input() public cancelButtonText: string = "Cancelar";
  @Input() public cancelButtonIcon: string = "";
  @Input() public cancelButtonClass: string = "info-button";
  @Input() public confirmButtonText: string = "Confirmar";
  @Input() public confirmButtonIcon: string = "";
  @Input() public confirmButtonClass: string = "primary-button";
  @Input() public hasCallBack: boolean = false;
  @Input() public callBackFunction: Function;
  @Input() public cancelCallBackFunction: Function;
  @Input() public width: string;
  @Input() public height: string;

  @ViewChild('modal') modal: ElementRef;

  /*Show/Hide*/
  @Output() showChange = new EventEmitter<boolean>();

  private _show: boolean = false;

  @Input() set show(show: boolean)
  {
    this._show = show;
    this.showChange.emit(this._show);
  }

  get show(): boolean
  {
    return this._show;
  }

  private _preventCloseFromBackDrop: boolean = false;
  @Input() set preventCloseFromBackDrop(preventCloseFromBackDrop: boolean)
  {
    this._preventCloseFromBackDrop = preventCloseFromBackDrop;
  }

  get preventCloseFromBackDrop(): boolean
  {
    return this._preventCloseFromBackDrop;
  }

  constructor(private renderer: Renderer2)
  {
    this.renderer.listen('window', 'click', (e: Event) =>
    {
      if (e.target === this.modal?.nativeElement && !this._preventCloseFromBackDrop)
      {
        this.show = false;
        this.showChange.emit(this._show);
      }
    });
  }

  ngOnChanges(): void
  {
    this._show = this.show;
  }
}
